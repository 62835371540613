import React from 'react'

import { isMobile } from 'react-device-detect'

import styles from './ContactInfo.module.scss'

const ContactInfo: React.FC = () => {
  return (
    <address className={styles.contactInfoContainer}>
      <h3 className={styles.title}>Informações de contato</h3>
      <h4 className={styles.subTitle}>Endereço</h4>
      <p className={styles.commonText}>
        Rua XV de Novembro, nº1.504 - Bairro Alto <br /> Piracicaba / SP, CEP:
        13.419-235
      </p>
      <h4 className={styles.subTitle}>Telefone</h4>
      <p className={styles.commonText}>
        {isMobile ? (
          <a href='tel:+551934226222'>(19) 3422-6222</a>
        ) : (
          <p>(19) 3422-6222</p>
        )}
      </p>
      <h4 className={styles.subTitle}>Email</h4>
      <p className={styles.commonText}>
        <a href='mailto:contato@pagimovel.com.br'>contato@pagimovel.com.br</a>
      </p>
    </address>
  )
}

export default React.memo(ContactInfo)
